import {FaReact ,FaNode, FaCss3Alt} from 'react-icons/fa';
import {SiJavascript} from 'react-icons/si'
import { BiLogoFlutter,BiLogoMongodb } from 'react-icons/bi';

// import Project1 from '../assets/Project1.png'
// import Project2 from '../assets/Project2.png'
import Project1 from '../assets/drhero-project.png'
import Project2 from '../assets/xiafit-project.png'


export const Skills = [
    {
        id: 0,
        tech: 'Flutter',
        icon: <BiLogoFlutter/>,

    },
    {
        id: 1,
        tech: 'Node JS',
        icon: <FaNode/>
    },
    {
        id: 2,
        tech: 'CSS',
        icon: <FaCss3Alt/>
    },
    {
        id: 3,
        tech: 'Javascript',
        icon: <SiJavascript/>
    },
    {
        id: 4,
        tech: 'React JS',
        icon: <FaReact/>
    },
    {
        id: 5,
        tech: 'MongoDB',
        icon: <BiLogoMongodb/>
    },
      
];

export const projectDetails = [
    {
        id: 0,
        project_name: "Dr Hero",
        project_desc:"Application for remote medical consultations with qualified healthcare professionals. Dedicated apps for accessing medical information, scheduling appointments, and receiving health recommendations. Prioritizes patient privacy and security, adhering to stringent data protection regulations to safeguard patient information and maintain confidentiality.",
        tech_stack: ['Riverpod', 'Stripe','AWS','Firebase'],
        project_img: Project1,
        project_url: 'https://play.google.com/store/apps/details?id=com.digitalrx.dr.hero&hl=en_IN',
        reverse:false
    },
    {
        id: 1,
        project_name: "XiaFit",
        project_desc: "Developed a comprehensive health and fitness application designed to cater to individual user needs, integrating diet plans, workout sessions, and personalized coaching. Implemented a feature allowing users to access personalized diet plans based on their health goals and dietary preferences. Integrated a real-time chat functionality enabling seamless communication between users and assigned coaches for guidance, motivation, and support. Incorporated video tutorials for each workout session, enhancing user understanding and adherence to proper exercise techniques.",
        tech_stack: ['Provider', 'Chat','Firebase'],
        project_img: Project2,
        project_url: 'https://www.xiafit.com',
        reverse:true
    },

];

export const navLinks = [
    {
        id: 0,
        name: 'Home',
        href: 'Home'
    },
    {
        id: 1,
        name: 'My Skills',
        href: 'Skills'
    },
    {
        id: 2,
        name: 'My Projects',
        href: 'Projects'
    },
    {
        id: 3,
        name: 'My Contact',
        href: 'Contact'
    },
]